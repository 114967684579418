import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Obfuscate from "react-obfuscate";

export const pageQuery = graphql`
  {
    splashImage: file(relativePath: { eq: "highway-intersection.jpg" }) {
      ...SplashImage
    }
    site {
      siteMetadata {
        defaultLanguage
      }
    }
  }
`;

export default function TeamPage({ data }) {
  const intl = useIntl();

  return (
    <Layout intl={intl} title={intl.formatMessage({ id: "team.title", defaultMessage: "Meet the ubicube team" })}>
      <Seo intl={intl} title={intl.formatMessage({ id: "team.title", defaultMessage: "Meet the ubicube team" })} />
      <SplashTitle
        image={getImage(data.splashImage)}
        subheadline={intl.formatMessage(
          { id: "team.subheadline", defaultMessage: "Meet the <mark>ubicube</mark> Team" },
          { mark: (text) => <mark>{text}</mark> }
        )}
      ></SplashTitle>
      <main>
        <div className="bg-white max-w-screen-2xl mx-auto">
          <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              <div className="space-y-5 sm:space-y-4">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                  {intl.formatMessage({ id: "team.ourteam", defaultMessage: "Our Team" })}
                </h2>
                <p className="text-xl text-gray-500">
                  <FormattedMessage
                    id="team.headline-text"
                    defaultMessage="The ubicube team is working hard on creating valueable services for its customers. This is us!"
                  />
                </p>
              </div>
              <div className="lg:col-span-2">
                <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                  <li>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src="/team/uc-as-portrait.png" alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Dr. Andreas Salentinig</h3>
                        <p className="text-uc-600">Founder and CEO</p>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                          <FormattedMessage
                            id="team.description-andreas"
                            defaultMessage="Ever since he was a little kid, Andreas has been fascinated by maps. That’s also why he decided to pursue a career in Earth Observation. At ubicube, Andreas can go after his passion and create informative maps and services for our customers."
                          />
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://www.linkedin.com/in/andreas-salentinig-29815250/" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <Obfuscate email="andreas@ubicube.eu">email me!</Obfuscate>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src="/team/uc-wb-portrait.png" alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Dr. Wolfgang Brunauer</h3>
                        <p className="text-uc-600">Co-Founder</p>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                          <FormattedMessage
                            id="team.description-wolfgang"
                            defaultMessage="Wolfgang joined ubicube as a co-founder in July 2024, focusing on business development. With a PhD in Economics from the University of Innsbruck, he brings extensive experience in data science for real estate. Wolfgang is also the CEO and founder of DataScience Service GmbH, where he has been developing data science solutions for the real estate industry since 2016. His background includes roles as a credit risk analyst at UniCredit Bank Austria and teaching positions at FH Kufstein Tirol and FH Wien der WKW. Wolfgang's expertise in econometrics, credit risk assessment, and real estate valuation, combined with his entrepreneurial experience, strengthens ubicube's capabilities in developing cutting-edge geospatial AI solutions for the real estate and energy sectors."
                          />
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://www.linkedin.com/in/wolfgang-brunauer-12487a86/?originalSubdomain=at" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <Obfuscate email="wolfgang@ubicube.eu">email me!</Obfuscate>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src="/team/uc-ts-portrait.png" alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Tanya Singh, MMMSc</h3>
                        <p className="text-uc-600">Geospatial developer</p>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                          <FormattedMessage
                            id="team.description-tanya"
                            defaultMessage="Tanya has expertise in creating and analyzing geospatial data, developing interactive mapping applications and geovisualization tools, and working with large datasets using advanced techniques such as machine learning and big data. She is passionate about bridging the gap between AI and Earth Observation and leveraging the potential of big geodata to solve real-world problems. "
                          />
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://www.linkedin.com/in/tanya-singh-360a65bb/" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <Obfuscate email="tanya@ubicube.eu">email me!</Obfuscate>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src="/team/uc-yg-portrait.png" alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Dr. Yunya Gao</h3>
                        <p className="text-uc-600">Geospatial Developer</p>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                          <FormattedMessage
                            id="team.description-yunya"
                            defaultMessage="Yunya joined ubicube in December 2024, bringing expertise in neural networks for building detection, change detection, and other geospatial applications. With a PhD in Geographic Information Science from the University of Salzburg, she specializes in Earth Observation for Humanitarian Action. Her background includes experience at Spacept analyzing nighttime satellite imagery and building damage assessment, as well as research positions at Technical University Berlin and Tsinghua University. Yunya serves on NASA's Earth Lifelines Science Review Board, connecting science with humanitarian challenges. Her research on leveraging AI models like SAM (Segment Anything Model) for refugee camp building identification demonstrates her commitment to applying geospatial technology to humanitarian causes."
                          />
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://www.linkedin.com/in/yunya-gao-9b72b1148/?originalSubdomain=at" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <Obfuscate email="yunya@ubicube.eu">email me!</Obfuscate>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
